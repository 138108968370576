import React, {FC, useState} from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';

import IconButton from 'components/IconButton';

import Instagram from 'images/icons/instagramIcon.svg';
// import Facebook from 'images/icons/facebookIcon.svg';
import WhatsApp from 'images/icons/whatsappIcon.svg';

import Burger from 'images/icons/burgerIcon.svg';
import Close from 'images/icons/closeIcon.svg';
import Foni from 'images/foni_logo.svg';

import menuStyles from 'styles/menu.module.scss';

type MenuItem = {name: string; path: string; isOutside?: boolean};

const renderSingleItem = (
  name: string,
  path: string,
  isOutside = false,
  closeMenu: () => void,
) => {
  return (
    <a
      key={name}
      href={path}
      target={isOutside ? '_blank' : undefined}
      rel={isOutside ? 'external' : ''}
      className={menuStyles.menu_item_mobile}
      onClick={closeMenu}
    >
      {name}
    </a>
  );
};

const renderAllItens = (menuItens: MenuItem[], closeMenu: () => void) =>
  menuItens.map(({name, path, isOutside}) =>
    renderSingleItem(name, path, isOutside, closeMenu),
  );

interface Props {
  menuItens: MenuItem[];
}

const MobileMenu: FC<Props> = ({menuItens}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  const renderSwitch = () => (
    <button onClick={menuOpen ? closeMenu : openMenu}>
      <div className={menuStyles.logo_container}>
        {menuOpen ? <Close /> : <Burger />}
      </div>
    </button>
  );

  return (
    <div className={menuStyles.menu_container}>
      <div className={menuStyles['main-logo']}>
        <Link to="/" style={{textDecoration: `none`}}>
          <Foni />
        </Link>
      </div>
      {renderSwitch()}
      <div
        className={clsx(
          menuStyles.menu_burger_container,
          menuOpen && menuStyles.open,
        )}
      >
        <div className={menuStyles.menu_itens_container}>
          {renderAllItens(menuItens, closeMenu)}
          <div className={menuStyles.line} />
        </div>
        <div>
          <p className="fs-16">
            R. Bom Pastor, 2224 - conjuntos 1210-1305 - Ipiranga, São Paulo- SP,
            04203-002
          </p>
          <p className="fs-16">Telefone: (11) 93284-9257</p>
          <p className="fs-16">
            Instagram:{' '}
            <a
              href="https://www.instagram.com/foniclinicainfantil/"
              target="_blank"
            >
              @foniclinicainfantil
            </a>
          </p>
        </div>
        {/* <div className={menuStyles.social}>
          <IconButton
            icon={<Instagram />}
            href="https://www.instagram.com/foniclinicainfantil/"
          />
          <IconButton
            icon={<WhatsApp />}
            href="https://api.whatsapp.com/send?phone=5511932849257"
          />{' '}
        </div> */}
      </div>
    </div>
  );
};

export default MobileMenu;
