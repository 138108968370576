import React, {FC} from 'react';
import {Link} from 'gatsby';

import Foni from 'images/foni_logo.svg';

import menuStyles from 'styles/menu.module.scss';

type MenuItem = {name: string; path: string; isOutside?: boolean};

const renderSingleItem = (name: string, path: string, isOutside: boolean) => {
  return (
    <a
      href={path}
      target={isOutside ? '_blank' : undefined}
      rel={isOutside ? 'external' : ''}
      className={menuStyles.menu_item}
      key={name}
    >
      {name}
    </a>
  );
};

const renderAllItens = (itensArray: MenuItem[]) =>
  itensArray.map(({name, path, isOutside = false}) =>
    renderSingleItem(name, path, isOutside),
  );

interface Props {
  menuItens: MenuItem[];
}

const DesktopMenu: FC<Props> = ({menuItens}) => (
  <div className={menuStyles.menu_container}>
    <div className={menuStyles['main-logo']}>
      <Link to="/" style={{textDecoration: `none`}}>
        <Foni />
      </Link>
    </div>
    <div className={menuStyles.menu_itens_container}>
      {renderAllItens(menuItens)}
    </div>
  </div>
);

export default DesktopMenu;
