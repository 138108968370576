import React, {FC} from 'react';

import {BreakpointProvider} from 'hooks/useBreakpoint';

import Header from 'components/header';
import PreHeader from 'components/preHeader';

import 'styles/layout.scss';
import 'styles/theme.css';

const Layout: FC = ({children}) => {
  if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"]');
  }

  return (
    <BreakpointProvider>
      <PreHeader />
      <Header />
      <div className="layout">
        <main>{children}</main>
      </div>
    </BreakpointProvider>
  );
};

export default Layout;
