import React, {useState, useEffect, FC} from 'react';
import {createContext, useContextSelector} from 'use-context-selector';

interface Breakpoints {
  xsUp: boolean;
  xsDown: boolean;
  smUp: boolean;
  smDown: boolean;
  smdUp: boolean;
  smdDown: boolean;
  mdUp: boolean;
  mdDown: boolean;
  lgUp: boolean;
  lgDown: boolean;
  xlUp: boolean;
  xlDown: boolean;
  or: boolean;
}

type Queries =
  | 'xsUp'
  | 'xsDown'
  | 'smUp'
  | 'smDown'
  | 'smdUp'
  | 'smdDown'
  | 'mdUp'
  | 'mdDown'
  | 'lgUp'
  | 'lgDown'
  | 'xlUp'
  | 'xlDown'
  | 'or';

const defaultValue: Breakpoints = {
  xsUp: false,
  xsDown: false,
  smUp: false,
  smDown: false,
  smdUp: false,
  smdDown: false,
  mdUp: false,
  mdDown: false,
  lgUp: false,
  lgDown: false,
  xlUp: false,
  xlDown: false,
  or: false,
};

const BreakpointContext = createContext<Breakpoints>(defaultValue);

const sizes = {
  xs: 0,
  sm: 600,
  smd: 780,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const BreakpointProvider: FC = ({children}) => {
  const [queryMatch, setQueryMatch] = useState({} as Breakpoints);

  useEffect(() => {
    const queries = {
      xsUp: `(min-width: ${sizes.xs}px)`,
      xsDown: `(max-width: ${sizes.xs}px)`,
      smUp: `(min-width: ${sizes.sm}px)`,
      smDown: `(max-width: ${sizes.sm}px)`,
      smdUp: `(min-width: ${sizes.smd}px)`,
      smdDown: `(max-width: ${sizes.smd}px)`,
      mdUp: `(min-width: ${sizes.md}px)`,
      mdDown: `(max-width: ${sizes.md}px)`,
      lgUp: `(min-width: ${sizes.lg}px)`,
      lgDown: `(max-width: ${sizes.lg}px)`,
      xlUp: `(min-width: ${sizes.xl}px)`,
      xlDown: `(max-width: ${sizes.xl}px)`,
      or: '(orientation: portrait)',
    };

    const mediaQueryLists = {} as Record<string, MediaQueryList>;
    const keys = Object.keys(queries) as Queries[];
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {} as Breakpoints);
      setQueryMatch(updatedMatches);
    };

    if (typeof window !== 'undefined' && window.matchMedia) {
      const matches = {} as Breakpoints;
      keys.forEach(media => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach(media => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }
    return () => {
      if (isAttached) {
        keys.forEach(media => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [sizes.xs, sizes.sm, sizes.md, sizes.lg, sizes.xl, sizes.smd]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

const useBreakpoint = (query: Queries): boolean =>
  useContextSelector(BreakpointContext, info => info[query]);

export {useBreakpoint, BreakpointProvider};
