import React from 'react';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

type NameMetaObj = {
  name: string;
  content: string;
};

type PropertyMetaObj = {
  property: string;
  content: string;
};

type Meta = ConcatArray<NameMetaObj | PropertyMetaObj>;

type SEOProps = {
  title: string;
  description?: string;
  lang?: string;
  meta?: Meta;
};

function Seo({description = '', lang = 'pt-br', meta = [], title}: SEOProps) {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  const image = site.siteMetadata.image;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      meta={[
        {name: `description`, content: metaDescription},
        {property: `og:title`, content: title},
        {property: `og:description`, content: metaDescription},
        {property: `og:type`, content: `website`},
        {property: 'og:image', content: image},
        {property: 'og:locale', content: 'pt_BR'},
        {name: `twitter:card`, content: `summary`},
        {name: `twitter:creator`, content: site.siteMetadata?.author || ``},
        {name: `twitter:title`, content: title},
        {name: `twitter:description`, content: metaDescription},
        {name: 'twitter:image', content: image},
      ].concat(meta)}
    />
  );
}

export default Seo;
