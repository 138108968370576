import React, {FC} from 'react';
import clsx from 'clsx';

import {useBreakpoint} from 'hooks/useBreakpoint';

import IconButton from 'components/IconButton';

import Instagram from 'images/icons/instagramIcon.svg';
import Linkedin from 'images/icons/linkedinIcon.svg';
import Maps from 'images/icons/mapsIcon.svg';
import WhatsApp from 'images/icons/whatsappIcon.svg';

import preHeaderStyles from 'styles/preHeader.module.scss';

const PreHeader: FC = () => {
  const isSmd = useBreakpoint('smdUp');

  return (
    <div className={preHeaderStyles.container}>
      <div className={clsx(preHeaderStyles.content)}>
        <div className={preHeaderStyles.social}>
          <IconButton
            icon={<Instagram />}
            href="https://www.instagram.com/foniclinicainfantil/"
          />
          <IconButton
            icon={<Linkedin />}
            href="https://www.linkedin.com/company/fon%C3%ADclinicainfantil/"
          />
          {!isSmd && (
            <IconButton
              icon={<WhatsApp />}
              href="https://api.whatsapp.com/send/?phone=5511932849257&text=Ol%C3%A1%2C+eu+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0"
            />
          )}
        </div>
        <div className={preHeaderStyles.address}>
          <a
            target="_blank"
            href="https://www.google.com/maps/dir//FON%C3%8D+-+Cl%C3%ADnica+Infantil+-+R.+Bom+Pastor,+2224+-+conjunto+1210+-+Ipiranga,+S%C3%A3o+Paulo+-+SP,+04203-002/@-23.3379181,-47.0638996,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x94ce5b52a6d3d735:0xeb16c49c8811414a!2m2!1d-46.6064813!2d-23.5962349"
          >
            {' '}
            R. Bom Pastor, 2224 - {isSmd &&
              ' conjuntos 1210-1305 - Ipiranga,'}{' '}
            São Paulo
            {isSmd && '- SP'}
            {isSmd && ', 04203-002'}
          </a>
          <IconButton
            icon={<Maps />}
            href="https://www.google.com/maps/dir//FON%C3%8D+-+Cl%C3%ADnica+Infantil+-+R.+Bom+Pastor,+2224+-+conjunto+1210+-+Ipiranga,+S%C3%A3o+Paulo+-+SP,+04203-002/@-23.3379181,-47.0638996,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x94ce5b52a6d3d735:0xeb16c49c8811414a!2m2!1d-46.6064813!2d-23.5962349"
          />
        </div>

        {isSmd && (
          <div className={preHeaderStyles.contact}>
            <a
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=5511932849257&text=Ol%C3%A1%2C+eu+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0"
            >
              {isSmd && 'Tel/Whatsapp: (11) 93284-9257'}
            </a>
            <IconButton
              icon={<WhatsApp />}
              href="https://api.whatsapp.com/send/?phone=5511932849257&text=Ol%C3%A1%2C+eu+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0"
            />{' '}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreHeader;
