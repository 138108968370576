import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import {useBreakpoint} from 'hooks/useBreakpoint';

import DesktopMenu from 'components/DesktopMenu';
import MobileMenu from 'components/MobileMenu';

import headerStyles from 'styles/header.module.scss';

const menuItens = [
  {name: 'Sobre nós', path: '/#sobre'},
  {name: 'Atuação', path: '/#oquefazemos'},
  {name: 'Especialidades', path: '/#especialidades'},
  {name: 'Equipe', path: '/#equipe'},
  {name: 'Espaço', path: '/#galeria'},
  {name: 'Infos', path: '/#informacoes'},
  // {name: 'Blog', path: '/#siteemconstrucao'},
];

const Header = () => {
  const isSmd = useBreakpoint('smdUp');

  const [scrollDown, setScrollDown] = useState(true);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDown = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDown(scrollY < lastScrollY || scrollY == 0 ? true : false);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDown);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDown]);
  return (
    <header
      className={clsx(headerStyles.container, scrollDown && headerStyles.move)}
    >
      <div className={headerStyles.content}>
        {isSmd ? (
          <DesktopMenu menuItens={menuItens} />
        ) : (
          <MobileMenu menuItens={menuItens} />
        )}
      </div>
    </header>
  );
};

export default Header;
