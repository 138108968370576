import React, {FC} from 'react';
import clsx from 'clsx';

import styles from 'styles/IconButton.module.scss';

interface Props {
  icon: any;
  href: string;
  text?: string;
}
const IconButton: FC<Props> = ({icon, href, text}) => {
  return (
    <>
      <a
        href={href}
        className={clsx(styles['icon-button'])}
        target="_blank"
        rel="external"
        tabIndex={0}
      >
        {icon}
      </a>
      {text && <p>{text}</p>}
    </>
  );
};

export default IconButton;
